import styles from "./Input.module.scss";
import { useState } from "react";
import classNames from "classnames/bind";

const Input = ({ ...props }) => {
  const { value, onChange, placeholder, required, maxLength, className, type } = props;

  const [isFocus, setIsFocus] = useState(false);
  const [isEntered, setIsEntered] = useState(false);
  const cx = classNames.bind(styles);

  const invalid = isEntered && required && value.length === 0;
  const containerClass = cx("container", className, { invalid: invalid });

  return (
    <div className={containerClass}>
      {type === "textarea" ? (
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type="text"
          maxLength={maxLength ? maxLength : 3000}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false);
            setIsEntered(true);
          }}
        />
      ) : (
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type="text"
          maxLength={maxLength ? maxLength : 100}
          onFocus={() => setIsFocus(true)}
          onBlur={() => {
            setIsFocus(false);
            setIsEntered(true);
          }}
        />
      )}
      {isFocus && maxLength && (
        <p className={styles.length}>
          {!value ? 0 : value.length}/{maxLength}
        </p>
      )}
      <p className={styles.message}>{invalid && "入力は必須です。"}</p>
    </div>
  );
};

export default Input;
