import styles from "./Navbar.module.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Navbar = () => {
  return (
    <nav className={styles.container}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo width="152px" height="40px" />
        </div>
      </div>
      <div className={styles.right}>
        <AnchorLink className={styles.link} href="#intro">
          事業紹介
        </AnchorLink>
        <AnchorLink className={styles.link} href="#member">
          メンバー
        </AnchorLink>
        <AnchorLink className={styles.link} href="#company">
          運営会社
        </AnchorLink>
        <AnchorLink className={styles.link} href="#contact">
          お問い合わせ
        </AnchorLink>
      </div>
    </nav>
  );
};

export default Navbar;

const Logo = ({ ...props }) => {
  return (
    <div className={styles.logo}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="223.5"
        height="68"
        viewBox="0 0 223.5 68"
        {...props}
      >
        <defs>
          <clipPath id="clipPath">
            <rect
              width="98"
              height="68"
              transform="translate(15.5 12.986)"
              fill="#fff"
              stroke="#707070"
              strokeWidth="1"
            />
          </clipPath>
        </defs>
        <g transform="translate(34.5 18.014)">
          <text
            id="Poco"
            transform="translate(64 5.486)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1"
            fontSize="22"
            fontFamily="LaoSangamMN, Lao Sangam MN"
          >
            <tspan x="0" y="22">
              OKOSUMO
            </tspan>
          </text>
          <g transform="translate(-50 -31)" clipPath="url(#clipPath)">
            <image
              id="logo01_k"
              width="98"
              height="98"
              transform="translate(15.5 12.986)"
              href="/assets/logo/okosumo.png"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
