import styles from "./PCHero.module.scss";
import classNames from "classnames/bind";
import Button from "./Button";

const PCHero = ({ ...props }) => {
  const { imageIndex, updateImageIndex } = props;
  const cx = classNames.bind(styles);
  const hideImage = cx("image", "hide");
  const activeDot = cx("dot", "active");

  const heros = [
    "/assets/hero/image1.jpg",
    "/assets/hero/image2.jpg",
    "/assets/hero/image3.jpg",
    "/assets/hero/image4.jpg",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <div className={styles.over}>
        <div className={styles.left}>
          <h1 className={styles.title}>世の中を支える</h1>
          <h1 className={styles.title}>「原動力」になる</h1>
          <h4 className={styles.text}>
            オコスモは人々が年齢・性別・金銭といった、様々なしがらみに囚われずに挑戦できる社会を実現し、イノベーションの可能性を最大化することを経営目標としています。
          </h4>
          <Button outlined href="#contact" className={styles.button}>
            お問い合わせ
          </Button>
        </div>
        <div className={styles.right}>
          {heros.map((src, index) => {
            return (
              <img
                src={src}
                width={717}
                height={403}
                key={index}
                className={imageIndex !== index ? hideImage : styles.image}
                alt="オコスモ制作例"
              />
            );
          })}
          <div className={styles.wrapper}>
            {heros.map((_, index) => {
              return (
                <div
                  className={imageIndex !== index ? styles.dot : activeDot}
                  key={index}
                  onClick={() => updateImageIndex(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCHero;
