import styles from "./App.module.scss";
import classNames from "classnames/bind";
import Button from "./components/Button";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import PCHero from "./components/PCHero";
import SmartPhoneHero from "./components/SmartPhoneHero";

const App = () => {
  const cx = classNames.bind(styles);
  // const reverse = cx("section2", "reverse");
  const topHeadline = cx("headline", "top");
  const [imageIndex, setImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex) => (imageIndex + 1) % 4);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const members = [
    {
      name: "古田 拓也",
      carrer: "オコスモ 代表取締役CEO",
      description:
        "1994年生まれ。中央大学卒業後、2017年4月に株式会社Finatextへ入社。在学中は同社のグループ証券会社「スマートプラス」の設立に向けてCFOの元で経営計画補助や、財務当局向けの概要書制作に携わる。入社後はauPAY「お金の管理」機能や、クレディセゾンの投信積み立てサービス「セゾンポケット」等のディレクターとして様々な事業会社のアプリ製作を担当。2022年2月より現職。1級FP技能士・FP技能士センター正会員。",
      photoSrc: "/assets/member/furuta.jpg",
      facebook: "https://www.facebook.com/takuya.furuta1/",
      linkdin: "https://www.linkedin.com/in/takuya-furuta-0547a9182/",
      twitter: "https://twitter.com/full_tangent",
    },
    {
      name: "T.I",
      carrer: "アートディレクター",
      description:
        "1998年生まれ。大学ではビジネス応用心理学とデザイン、動画を専門に研究。2020年頃からメタバースに没頭し、3D分野に研究の幅を広げる。オコスモではサービス全般のアートディレクションおよびデザインを手がけ、ユーザーフレンドリーなクリエイティブの製作をリードする。",
      photoSrc: "/assets/member/noimage.png",
    },
    {
      name: "T.S",
      carrer: "アシスタントプロデューサー",
      description:
        "1997年生まれ。法政大学卒業後、Vtuberグループのプロデュースを手がけ、マネジメントも行う。2021年8月にオコスモに参画。ユニバースプロダクション事業では、Unityを活用したアセットやステージ製作、配信演出、ライバーマネジメント等を手がける。",
      photoSrc: "/assets/member/ts.png",
    },
    {
      name: "H.M",
      carrer: "モーションテクニカルエンジニア",
      description:
        "1996年生まれ。法政大学在学中より、Vtuber四天王と呼ばれる著名インフルエンサーの1人をマネジメントした後、起業。累計ch登録者数100万人を超えるVtuberをプロデュースした。2021年8月にオコスモへ参画。ユニバースプロダクション事業では、光学キャプチャやUnityを活用するモーションテクニカルエンジニアとして3D空間における技術開発をリード。",
      photoSrc: "/assets/member/hm.png",
    },
    // { name: "Coming Soon" }
  ];

  const latLng = { lat: 35.732006, lng: 139.719202 };

  const center =
    window.innerWidth < 600 ? { lat: 35.731, lng: 139.716 } : { lat: 35.731, lng: 139.714 };

  return (
    <div className={styles.container}>
      <Navbar />
      <MediaQuery query="(min-width: 1080px)">
        <PCHero
          imageIndex={imageIndex}
          updateImageIndex={(imageIndex) => setImageIndex(imageIndex)}
        />
      </MediaQuery>
      <MediaQuery query="(max-width: 1080px)">
        <SmartPhoneHero
          imageIndex={imageIndex}
          updateImageIndex={(imageIndex) => setImageIndex(imageIndex)}
        />
      </MediaQuery>
      <div className={topHeadline} id="intro">
        <div className={styles.top}>
          <h2 className={styles.title}>グループ経営管理事業</h2>
          <p className={styles.text}>
            オコスモはグループ企業の経営管理を主軸としています。当社のグループ企業の第一号である、カンバンクラウド株式会社は「広告を感動体験にする」をビジョンに、広告業界のDX化をサポートするSaaSプラットフォームを開発しています。
          </p>
        </div>
      </div>
      <div className={styles.section1}>
        <div className={styles.content}>
          <MediaQuery query="(max-width: 800px)">
            <img
              src="/assets/logo/kanbancloud.png"
              width={200}
              height={48}
              alt="カンバンクラウド"
              className={styles.logo}
            />
          </MediaQuery>
          <div className={styles.left}>
            <img
              src="/assets/photo/kanbancloud.png"
              width="100%"
              alt="クラウド完結で広告が売れるマーケットプレイス"
            />
          </div>
          <div className={styles.right}>
            <MediaQuery query="(min-width: 800px)">
              <img
                src="/assets/logo/kanbancloud.png"
                width={200}
                height={48}
                alt="カンバンクラウド"
                className={styles.logo}
              />
            </MediaQuery>
            <h1 className={styles.title}>
              広告枠の販売・売上管理{"\n"}
              クラウドで完結
            </h1>
            <p className={styles.text}>
              カンバンクラウドでは、ネットショップのような感覚で自由に広告枠を出品することができます。お客様同士のやり取りや売上の管理はカンバンクラウド内で一元管理が可能です。{" "}
            </p>
            <Button outlined href="https://kanban-cloud.com" blank>
              カンバンクラウド株式会社のWEBページへ
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.headline}>
        <div className={styles.top}>
          <h2 className={styles.title}>関連企業</h2>
        </div>
      </div>
      <div className={styles.section2}>
        <div className={styles.left}>
          <img
            src="/assets/logo/unibirth.png"
            width={334}
            height={121}
            className={styles.logo}
            alt="ユニバースプロダクション"
          />
          <MediaQuery query="(min-width: 800px)">
            <p className={styles.text}>
              ユニバースプロダクション株式会社は、法人向けのインフルエンサー運営事業を手がける事務所です。マネジメントだけでなく、著作権管理や広告代理事業など、社内インフルエンサーを伸ばしていくために必要なソリューションをまとめてご提供することが可能です。静岡新聞SBS公認Vtuber「木乃華サクヤ」をメインで運営しております。
            </p>
            <Button outlined href="https://unibirth.live/" blank>
              公式サイトへ
            </Button>
          </MediaQuery>
        </div>
        <div className={styles.right}>
          <div className={styles.image}>
            <img
              src="/assets/photo/case1.jpg"
              width="100%"
              className={styles.logo}
              alt="ユニバースプロダクション"
            />
            <a
              href="https://www.youtube.com/channel/UCSWkUurjlKM39CnS1hnfE_Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/logo/youtube.png"
                width={154}
                height={107}
                className={styles.youtube}
                alt="YouTube"
              />
            </a>
            <div className={styles.candy1} />
          </div>
        </div>
        <MediaQuery query="(max-width: 800px)">
          <div className={styles.left}>
            <p className={styles.text}>
              ユニバースプロダクション株式会社は、法人向けのインフルエンサー運営事業を手がける事務所です。マネジメントだけでなく、著作権管理や広告代理事業など、社内インフルエンサーを伸ばしていくために必要なソリューションをまとめてご提供することが可能です。静岡新聞SBS公認Vtuber「木乃華サクヤ」をメインで運営しております。
            </p>
          </div>
          <Button outlined href="https://unibirth.live/" blank>
            公式サイトへ
          </Button>
        </MediaQuery>
      </div>
      {/*<div className={reverse}>
        <MediaQuery query="(min-width: 800px)">
          <div className={styles.left}>
            <h1 className={styles.title}>分散型金融(DeFi)事業</h1>
            <p className={styles.text}>
              オコスモでは、資産の一部を米ドルにペッグしたステーブルコインに交換し、UniswapやCurveをはじめとした各種DeFiプロトコルにて運用しています。2022年3月時点の運用総額は51万1548ドルで、これらの暗号資産を分散金融市場における流動性供給やマーケットメイキングのために提供しております。{" "}
            </p>
            <div className={styles.candy2} />
          </div>
          <div className={styles.right}>
            <div className={styles.image}>
              <img
                src="/assets/photo/case2.png"
                width="100%"
                className={styles.logo}
                alt="分散型金融(DeFi)事業"
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery query="(max-width: 800px)">
          <div className={styles.left}>
            <h1 className={styles.title}>分散型金融(DeFi)事業</h1>
            <div className={styles.image}>
              <img
                src="/assets/photo/case2.png"
                width="100%"
                className={styles.logo}
                alt="分散型金融(DeFi)事業"
              />
            </div>
            <p className={styles.text}>
              オコスモでは、資産の一部を米ドルにペッグしたステーブルコインに交換し、UniswapやCurveをはじめとした各種DeFiプロトコルにて運用しています。2022年3月時点の運用総額は51万1548ドルで、これらの暗号資産を分散金融市場における流動性供給やマーケットメイキングのために提供しております。{" "}
            </p>
            <div className={styles.candy2} />
          </div>
        </MediaQuery>
  </div>*/}
      <div className={styles.headline} id="member">
        <div className={styles.top}>
          <h2 className={styles.title}>メンバー</h2>
        </div>
      </div>
      <div className={styles.section3}>
        <div className={styles.member}>
          {members.map(
            ({ name, carrer, description, photoSrc, facebook, linkdin, twitter }, index) => {
              return (
                <div className={styles.card} key={index}>
                  <img
                    src={photoSrc}
                    width={243}
                    height={360}
                    className={styles.photo}
                    alt={name}
                  />
                  <div className={styles.bottom}>
                    <h3 className={styles.name}>{name}</h3>
                    <p className={styles.carrer}>{carrer}</p>
                  </div>
                  <div className={styles.bg}>
                    <h4 className={styles.name}>{name}</h4>
                    <p className={styles.carrer}>{carrer}</p>
                    <div className={styles.sns}>
                      {facebook && (
                        <a href={facebook} target="_blank" rel="noopener noreferrer">
                          <img
                            src="/assets/sns/facebook.png"
                            width={24}
                            height={24}
                            alt="facebook"
                            className={styles.icon}
                          />
                        </a>
                      )}
                      {linkdin && (
                        <a href={linkdin} target="_blank" rel="noopener noreferrer">
                          <img
                            src="/assets/sns/linkdin.png"
                            width={24}
                            height={24}
                            alt="linkdin"
                            className={styles.icon}
                          />
                        </a>
                      )}
                      {twitter && (
                        <a href={twitter} target="_blank" rel="noopener noreferrer">
                          <img
                            src="/assets/sns/twitter.png"
                            width={24}
                            height={24}
                            alt="twitter"
                            className={styles.icon}
                          />
                        </a>
                      )}
                    </div>
                    <p className={styles.description}>{description}</p>
                  </div>
                </div>
              ); /* (
                <div className={styles.placeholder} key={index}>
                  <h2 className={styles.plus}>＋</h2>
                  <div className={styles.bottom}>
                    <h3 className={styles.name}>{name}</h3>
                    <p className={styles.carrer}>{carrer}</p>
                  </div>
                </div>
              );*/
            }
          )}
        </div>
      </div>
      <div className={styles.headline} id="company">
        <div className={styles.top}>
          <h2 className={styles.title}>運営会社</h2>
        </div>
      </div>
      <div className={styles.section4}>
        <div className={styles.map}>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLEMAP_API_KEY}>
            <GoogleMap
              mapContainerStyle={{ height: "400px", width: "100%" }}
              center={center}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
              }}
              zoom={16}
            >
              <Marker position={latLng} />
            </GoogleMap>
          </LoadScript>
          <div className={styles.address}>
            <div className={styles.card}>
              <h4 className={styles.name}>株式会社オコスモ</h4>
              <div className={styles.detail}>
                <h5 className={styles.text}>会社設立日</h5>
                <p className={styles.text}>2022年2月2日</p>
                <h5 className={styles.text}>会社所在地</h5>
                <p className={styles.text}>東京都豊島区東池袋3-11-9 下総屋ビル6階</p>
                <h5 className={styles.text}>電話番号</h5>
                <p className={styles.text}>03-6709-1109</p>
                <h5 className={styles.text}>代表取締役</h5>
                <p className={styles.text}>古田拓也</p>
                <h5 className={styles.text}>資本金</h5>
                <p className={styles.text}>950万円</p>
                <h5 className={styles.text}>メールアドレス</h5>
                <a
                  className={styles.text}
                  href="mailto:info@okosumo.com?subject=お問い合わせ"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@okosumo.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
