import styles from "./Button.module.scss";
import classNames from "classnames/bind";

const Button = ({ ...props }) => {
  const {
    href,
    blank,
    outlined,
    onClick,
    error,
    loading = false,
    className,
    children,
  } = props;

  const cx = classNames.bind(styles);
  const containerClass = cx("container", className, {
    outlined,
    loading,
  });

  return blank ? (
    <a
      href={href}
      className={containerClass}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : href ? (
    <a href={href} className={containerClass}>
      {children}
      {loading && <LoadingDots className={styles.loading} />}
      <p className={styles.error}>{error}</p>
    </a>
  ) : (
    <a onClick={onClick} className={containerClass}>
      {children}
      {loading && <LoadingDots className={styles.loading} />}
      <p className={styles.error}>{error}</p>
    </a>
  );
};

export default Button;

const LoadingDots = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512px"
      height="128px"
      viewBox="0 0 100 50"
      {...props}
    >
      <g transform="translate(25 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3333333333333333s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(50 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.16666666666666666s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(75 25)">
        <circle cx="0" cy="0" r="6" fill="currentColor">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
    </svg>
  );
};
