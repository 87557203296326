import styles from "./Footer.module.scss";
import Input from "./Input";
import EmailValidator from "email-validator";
import { useState } from "react";
import Button from "./Button";
import classNames from "classnames/bind";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");
  const [sended, setSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const cx = classNames.bind(styles);
  const textAreaClass = cx("text", "area");

  return (
    <footer className={styles.container}>
      <img src="/assets/footer/bg.png" className={styles.bg} alt="フッター" />
      <div className={styles.contact} id="contact">
        <h3 className={styles.title}>お問い合わせ</h3>

        <div className={styles.section}>
          <h6 className={styles.name}>お名前</h6>
          <div className={styles.input}>
            {!sended ? (
              <Input value={name} onChange={(e) => setName(e.target.value)} required type="input" />
            ) : (
              <p className={styles.text}>{name}</p>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <h6 className={styles.name}>メールアドレス</h6>
          <div className={styles.input}>
            {!sended ? (
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="input"
              />
            ) : (
              <p className={styles.text}>{email}</p>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <h6 className={styles.name}>お問い合わせ内容</h6>
          <div className={styles.input}>
            {!sended ? (
              <Input
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
                type="textarea"
              />
            ) : (
              <p className={textAreaClass}>{text}</p>
            )}
          </div>
        </div>

        {!sended && (
          <Button
            onClick={async () => {
              if (!text || !name || !email) {
                return setMessage("未入力の項目があります");
              }
              if (!EmailValidator.validate(email)) {
                return setMessage("メールアドレスの形式が正しくありません");
              }
              setLoading(true);
              const date = new Date();
              const contactData = {
                isChecked: false,
                created: `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`,
                email,
                name,
                text,
              };

              await fetch(
                `https://script.google.com/macros/s/${process.env.REACT_APP_GAS_DEPLOY_ID}/exec`,
                {
                  method: "POST",
                  mode: "no-cors",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    data: contactData,
                  }),
                }
              )
                .then(() => {
                  setMessage("お問い合わせを送信しました");
                  setSended(true);
                })
                .catch(() => setMessage("エラーが発生しました"));
              setLoading(false);
            }}
            outlined
            loading={loading}
            className={styles.button}
          >
            送信する
          </Button>
        )}
        <p className={styles.message}>{message}</p>
      </div>
      <div className={styles.copyright}>
        <h6 className={styles.text}>©︎ {new Date().getFullYear()} okosumo.inc</h6>
      </div>
    </footer>
  );
};

export default Footer;
